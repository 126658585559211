import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import howItWork from '../../assets/images/how-its-work.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                We love Innovation
                            </span>
                            <h2>Why Choose Us?</h2>
                            <p>With years of experience building software at scale, theParse is perfectly positioned to help leverage digital technologies for your business.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Simple and Transparent Development Model</h3>
                                    <p>Our clear and simple development process makes it easy for our customers to measure progress and associated outcomes.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Innovative, Scalable and Reliable Solutions</h3>
                                    <p>We provide scalable and robust software solutions. Our team of experts know what it takes to get a production ready solution!</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3 
                                    </div>
                                    <h3>Added Value</h3>
                                    <p>At the heart of it all is VALUE ADD. Our solutions are focused on delivering value for to you and your business.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs