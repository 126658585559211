import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    About Us
                                </span>
                                <h2>Bespoke, Innovative and Scalable Software.</h2>
                                <p>We are software! We specialise in bespoke software development for individuals and businesses.</p>
                                
                                <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>7+ years</h3>
                                        <p>In the Industry</p>
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>5+</h3>
                                        <p>Team members</p>
                                    </li>
                                    <li>
                                        <img src={icon6} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Satisfaction rate</p>
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Driven to win</p>
                                    </li>
                                </ul>
                                <p>Your digital future awaits! <span><a href="#">Contact us</a></span> today, to get started.</p>
                                
                                <Link to="/about-us" className="default-btn">
                                    <i className="flaticon-right"></i>More About Us<span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our History</h3>
                                <p>We are a software company founded with a vision to make software accessible to businesses in Africa.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Established in 2015</li>
                                    <li><i className="flaticon-tick"></i> Community driven</li>
                                    <li><i className="flaticon-tick"></i> Passionate about technology</li>
                                    <li><i className="flaticon-tick"></i> Growing businesses in Africa!</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>To build bespoke, innovative and scalable software solutions for the pan african market.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Focused on Co-creation.</li>
                                    <li><i className="flaticon-tick"></i> Shared Value</li>
                                    <li><i className="flaticon-tick"></i> Forming Partnerships </li>
                                    <li><i className="flaticon-tick"></i> Inventing the Future!</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Who we are</h3>
                                <p>We are a team of individuals with years of coporate experience designing, developing, testing and deploying a broad range of software solutions from home automation to enteprise standard solutions for small and medium sized business. Our team comprises of indivuals who have worked on complex global projects for huge organisations.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Driven to Win</li>
                                    <li><i className="flaticon-tick"></i> Lean Thinking & Lean Innovation</li>
                                    <li><i className="flaticon-tick"></i> Software as a Service</li>
                                    <li><i className="flaticon-tick"></i> Your brand, promoted!</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;